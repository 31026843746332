<template>
  <div class="custom-actions">
    <b-button
      class="m-1"
      variant="success"
      title="Edit Video"
      @click="itemAction('edit-item', rowData, rowIndex)"
    >
      <i class="fas fa-edit"></i>
    </b-button>

    <b-button
      class="m-1"
      variant="danger"
      title="Delete Video"
      @click="itemAction('delete-item', rowData, rowIndex)"
    >
      <i class="far fa-trash-alt"></i>
    </b-button>

    <b-button
      class="m-1"
      variant="primary"
      :title="rowData.notif_sent === 0 ? 'Send Notif' : 'Notif Sent'"
      @click="itemAction('notify-item', rowData, rowIndex)"
      :disabled="rowData.notif_sent === 1"
    >
      <i class="far fa-bell"></i>
    </b-button>
  </div>
</template>

<script>
  const EditMedia = () => import('./EditMedia.vue');
  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    methods : {

      /**
       * Delete/Edit Action
       *
       * @param action
       * @param data
       * @param index
       */
      // eslint-disable-next-line no-unused-vars
      itemAction(action, data, index) {
        if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title : 'Please confirm:',
            text  : `Are you sure you want to delete ` +
              `<b> ${data.media_title} </b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$http.delete('/api/media', {
                  data : {
                    'mediaId' : data.media_id,
                  },
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted media successfully.',
                  });

                  this.$parent.reload();
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            }, {
              title : 'No',
            } ],
          });
        } else if (action === 'edit-item') {
          this.$modal.show(
            EditMedia,
            { data : data },
            { height : 'auto', adaptive : true }
          );
        } else if (action === 'notify-item') {
          this.$modal.show('dialog', {
            title : 'Send Notification',
            text  : `Send New Video Notification for` +
              `<b> ${data.media_title}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$http.post('/api/notification/admin/new_video', {
                  mediaId : data.media_id,
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Notification sent.',
                  });
                  this.$parent.reload();
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            }, {
              title : 'No',
            } ],
          });
        }
      },
    },
    components : {
      // eslint-disable-next-line vue/no-unused-components
      EditMedia,
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/media-list-actions';
</style>